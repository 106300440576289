import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  search,
  clearSearch,
} from 'Webapp/shared/app/redux/actions/search-nav-actions';
import { getEditorialBoards } from 'Webapp/shared/app/redux/actions/content-actions';
import { usageTrackReceivedSearchResults } from 'Webapp/shared/app/redux/actions/usage-actions';
import Search from '../components/search';

const mapStateToProps = ({ searchNav, content }: Flipboard.State) => {
  const isFlipboardTvSupported =
    content &&
    content.contentGuide &&
    content.contentGuide.sections &&
    content.contentGuide.sections.some((s) => s.groupid === 'tv');

  return {
    loading: searchNav.loading,
    searchResults: searchNav.results,
    editorialBoards: content && content.editorialBoards,
    isFlipboardTvSupported,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getEditorialBoards,
      search,
      clearSearch,
      usageTrackReceivedSearchResults,
    },
    dispatch,
  );

const SearchContainer = connect(mapStateToProps, mapDispatchToProps)(Search);

export default SearchContainer;
