import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utils
import { euc } from 'Utils/url';
import SearchResultsUtils from 'Utils/content/search-results-util';
import WindowKeyDown, { KEYS } from 'Utils/window-key-down';

// Components
import { SelectedContext } from 'ComponentLibrary/context';
import Button, { StyleModifiers } from 'Webapp/shared/app/components/button';
import SectionTiles from '../section-tiles';
import Loading from '../loading';
import withT from 'ComponentLibrary/hocs/withT';

class SearchSections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: null,
      renderedIds: [],
      renderedTopResults: [],
      renderedTopics: [],
      renderedSources: [],
      renderedMagazines: [],
      renderedProfiles: [],
    };

    this.setRenderedTiles = this.setRenderedTiles.bind(this);
    this.moveNext = this.moveNext.bind(this);
    this.movePrevious = this.movePrevious.bind(this);
    this.windowKeyDown = new WindowKeyDown(this.handleKeyDown.bind(this));
  }

  componentDidMount() {
    this.windowKeyDown.subscribe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.setRenderedTiles();
    }
  }

  componentWillUnmount() {
    this.windowKeyDown.unsubscribe();
  }

  setRenderedTiles() {
    const { searchResults } = this.props;
    const renderedTopResults = SearchResultsUtils.resultItems(
      searchResults,
      'top_result',
    );
    const renderedTopics = SearchResultsUtils.resultItems(
      searchResults,
      'topic',
    ).slice(0, 4);
    const renderedSources = SearchResultsUtils.resultItems(
      searchResults,
      'source',
    ).slice(0, 4);
    const renderedMagazines = SearchResultsUtils.resultItems(
      searchResults,
      'magazine',
    ).slice(0, 4);
    const renderedProfiles = SearchResultsUtils.resultItems(
      searchResults,
      'profile',
    ).slice(0, 4);

    const renderedIds = [
      ...renderedTopResults,
      ...renderedTopics,
      ...renderedSources,
      ...renderedMagazines,
      ...renderedProfiles,
    ].map((i) => i.remoteid);
    this.setState({
      renderedTopResults,
      renderedTopics,
      renderedSources,
      renderedMagazines,
      renderedProfiles,
      renderedIds,
    });
  }

  handleKeyDown(e) {
    const { renderedIds, selectedIndex } = this.state;
    if (!renderedIds.length) {
      return;
    }
    switch (e.keyCode) {
      case KEYS.UP: {
        this.movePrevious();
        break;
      }
      case KEYS.LEFT: {
        if (selectedIndex === null) {
          return;
        }
        this.movePrevious();
        break;
      }
      case KEYS.DOWN: {
        this.moveNext();
        break;
      }
      case KEYS.RIGHT: {
        if (selectedIndex === null) {
          return;
        }
        this.moveNext();
        break;
      }
    }
  }

  moveNext() {
    const { renderedIds, selectedIndex } = this.state;
    let newIndex;
    if (selectedIndex === null || selectedIndex + 1 >= renderedIds.length) {
      newIndex = 0;
    } else {
      newIndex = selectedIndex + 1;
    }
    this.setState({ selectedIndex: newIndex });
  }

  movePrevious() {
    const { renderedIds, selectedIndex } = this.state;
    let newIndex;
    if (selectedIndex === null || selectedIndex - 1 < 0) {
      newIndex = renderedIds.length - 1;
    } else {
      newIndex = selectedIndex - 1;
    }
    this.setState({ selectedIndex: newIndex });
  }

  render() {
    const { searchQuery, searchResults, loading, t } = this.props;
    if (loading) {
      return <Loading />;
    }

    const {
      renderedTopResults,
      renderedTopics,
      renderedSources,
      renderedMagazines,
      renderedProfiles,
      renderedIds,
      selectedIndex,
    } = this.state;

    const itemCount = renderedIds.length;
    const selectedId =
      selectedIndex >= 0 && renderedIds.length
        ? renderedIds[selectedIndex]
        : null;

    return (
      <SelectedContext.Provider value={selectedId}>
        {[
          renderedTopResults.length > 0 && (
            <React.Fragment key="topResult">
              <SectionTiles
                title={t('top_result')}
                anchorId="top-result"
                sections={renderedTopResults}
              />
              <Button
                name="search-top-result-see-more"
                label={t('see_more')}
                href={`/search/${euc(searchQuery)}`}
                styleModifier={[StyleModifiers.BLOCK]}
              />
            </React.Fragment>
          ),
          renderedTopics.length > 0 && (
            <React.Fragment key="topics">
              <SectionTiles
                title={t('topic_plural')}
                anchorId="topics"
                sections={renderedTopics}
              />
              <Button
                name="search-topics-see-more"
                label={t('see_more_topics')}
                href={`/search/${euc(searchQuery)}`}
                styleModifier={[StyleModifiers.BLOCK]}
              />
            </React.Fragment>
          ),
          renderedSources.length > 0 && (
            <React.Fragment key="publishers">
              <SectionTiles
                title={t('publisher_plural')}
                anchorId="publishers"
                sections={renderedSources}
              />
              <Button
                name="search-publishers-see-more"
                label={t('see_more_publishers')}
                href={`/search/${euc(searchQuery)}`}
                styleModifier={[StyleModifiers.BLOCK]}
              />
            </React.Fragment>
          ),
          renderedMagazines.length > 0 && (
            <React.Fragment key="magazines">
              <SectionTiles
                title={t('magazine_plural')}
                anchorId="magazines"
                sections={renderedMagazines}
              />
              <Button
                name="search-magazine-see-more"
                label={t('see_more_magazines')}
                href={`/search/${euc(searchQuery)}`}
                styleModifier={[StyleModifiers.BLOCK]}
              />
            </React.Fragment>
          ),
          renderedProfiles.length > 0 && (
            <React.Fragment key="profiles">
              <SectionTiles
                title={t('profile_plural')}
                anchorId="profiles"
                sections={renderedProfiles}
              />
              <Button
                name="search-profiles-see-more"
                label={t('see_more_profiles')}
                href={`/search/${euc(searchQuery)}`}
                styleModifier={[StyleModifiers.BLOCK]}
              />
            </React.Fragment>
          ),
          // NOTE: Race condition where loading is false before we get searchResults
          searchResults && itemCount === 0 && !loading && (
            <p
              className="search--not-found ui-text--supporting"
              key="no-results"
            >
              {t('no_results')}
            </p>
          ),
        ]}
      </SelectedContext.Provider>
    );
  }
}

SearchSections.propTypes = {
  loading: PropTypes.bool.isRequired,
  searchResults: PropTypes.array,
  searchQuery: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

SearchSections.defaultProps = {
  searchResults: [],
};

export default withT(SearchSections);
