/* eslint-disable default-case */
import React, { Component } from 'react';

// Utils
import { euc } from 'Utils/url';
import Debouncer from 'Utils/debouncer';
import { GA } from 'Utils/analytics';
import { KEYS } from 'Utils/window-key-down';
import getWindow from 'Utils/get-window';
import scrollTo from 'Utils/scroll-to';
import { pushPathToHistory } from 'Utils/history';
import { USAGE_NAV_FROMS, USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import SectionUtil from 'Utils/content/section-util';

// Components
import Input, { InputIcons } from 'Webapp/shared/app/components/base/input';
import RecommendedSectionList from 'Webapp/shared/app/components/recommened-section-list';
import Loading from './loading';
import Button from 'Webapp/shared/app/components/button';
import WebLink from './web-link';
import SearchSections from './search/search-sections';
import { NavFromContext } from 'ComponentLibrary/context';

import withT from 'ComponentLibrary/hocs/withT';
import withHistory from 'Webapp/shared/app/hocs/withHistory';
import { RouteComponentProps } from 'react-router';

import connector from 'Utils/connector';
import connectAuthentication, {
  ConnectAuthenticationProps,
} from 'Webapp/shared/app/connectors/connectAuthentication';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';

type SearchPassedProps = {
  loading: boolean;
  getEditorialBoards: () => void;
  search: () => void;
  clearSearch: () => void;
  searchResults?: Flipboard.SearchResultsNav[];
  editorialBoards?: Array<Flipboard.EditorialBoard>;
  autoFocus?: boolean;
  usageTrackReceivedSearchResults: (
    searchTerm: string,
    itemCount: number,
  ) => void;
  isFlipboardTvSupported: boolean;
};

type SearchProps = ConnectAuthenticationProps &
  ConnectResponsiveProps &
  ConnectModalProps &
  SearchPassedProps & {
    // TODO convert withHistory
    history: RouteComponentProps['history'];
    t: Flipboard.TFunction;
  };

type SearchStates = { searchQuery: string };

class Search extends Component<SearchProps, SearchStates> {
  static defaultProps = {
    searchResults: null,
    autoFocus: false,
    editorialBoards: null,
  };
  constructor(props) {
    super(props);
    this.searchDebounced = Debouncer.create(
      this.props.search,
      250,
    ) as EventListener;

    this.inputRef = React.createRef();
    this.inputFocusTimer = undefined;
  }

  state = {
    searchQuery: '',
  };

  componentDidMount() {
    // Editorial Boards populate the initial list presented on the Search View.
    // If this list has already been loaded, skip request.
    if (!this.props.editorialBoards) {
      this.props.getEditorialBoards();
    }

    // On phones, the search modal input gets pushed out of the screen when the keyboard comes up.
    if (this.props.isPhone) {
      scrollTo();
    }

    this.inputFocusTimer = getWindow().setTimeout(() => {
      this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    }, 0);
  }

  componentWillUnmount() {
    this.props.clearSearch();
    getWindow().clearTimeout(this.inputFocusTimer);
  }

  inputRef: React.RefObject<HTMLInputElement>;
  searchDebounced: (string, boolean) => void;
  inputFocusTimer: number | undefined;

  handleChange = (e) => {
    this.setState(
      { [e.target.name]: e.target.value } as {
        [K in keyof SearchStates]: SearchStates[K];
      },
      () => {
        if (!this.state.searchQuery) {
          return;
        }
        this.searchDebounced(
          this.state.searchQuery,
          this.props.isAuthenticated,
        );
      },
    );
  };

  handleBlur = () => {
    const { searchResults, usageTrackReceivedSearchResults } = this.props;
    const { searchQuery } = this.state;
    const usageSearchResults =
      searchResults &&
      searchResults.length > 0 &&
      searchResults[0].searchResultItems;
    const itemCount = usageSearchResults ? usageSearchResults.length : 0;
    GA.trackSearch(searchQuery);
    usageTrackReceivedSearchResults(searchQuery, itemCount);
  };

  handleClear = () => {
    this.setState({ searchQuery: '' });
    this.props.clearSearch();
  };

  handleKeyUp = (event) => {
    if (event.keyCode === KEYS.ENTER) {
      const { searchQuery } = this.state;
      if (!searchQuery) {
        return;
      }
      pushPathToHistory(`/search/${euc(searchQuery)}`, this.props.history);
    }
  };

  handleSelect = (
    event: React.MouseEvent | React.KeyboardEvent,
    section: Flipboard.EditorialBoard,
  ) => {
    event.preventDefault();

    const path = SectionUtil.projection(section).canonicalPath;
    pushPathToHistory(path, this.props.history);
  };

  hasValue = () => {
    const { searchQuery } = this.state;
    return (
      (searchQuery && (searchQuery !== '' || searchQuery.length > 0)) || false
    );
  };

  renderLoading = () => {
    const { editorialBoards } = this.props;
    if (this.hasValue() || editorialBoards) {
      return null;
    }
    return <Loading />;
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <header className="search search__input-wrapper">
          <Input
            name="searchQuery"
            ref={this.inputRef}
            placeholder={t('search_flipboard')}
            autoComplete="off"
            icon={InputIcons.SEARCH}
            value={this.state.searchQuery}
            onChange={this.handleChange}
            onClear={this.handleClear}
            onBlur={this.handleBlur}
            onKeyUp={this.handleKeyUp}
            hasValue={this.hasValue()}
          />
          <Button
            name="search-modal-close"
            label={t('cancel')}
            onClick={this.props.dismissModal}
          />
        </header>
        <div className="search modal__content">
          {this.renderLoading()}
          {this.state.searchQuery ? (
            <NavFromContext.Provider value={USAGE_NAV_FROMS.SEARCH_RESULTS}>
              <SearchSections
                searchQuery={this.state.searchQuery}
                searchResults={this.props.searchResults}
                loading={this.props.loading}
              />
            </NavFromContext.Provider>
          ) : (
            <NavFromContext.Provider value={USAGE_NAV_FROMS.ZERO_STATE_SEARCH}>
              <p className="search__topic-supporting-copy ui-text--supporting">
                {t('search_description')}
              </p>
              {this.props.isFlipboardTvSupported && (
                <div className="search__flipboard-tv">
                  <WebLink
                    className="search__flipboard-tv-link"
                    href="/section/flipboard-tv-1bgrcui98s2ugoks"
                    navFromEventName={USAGE_EVENT_NAMES.SECTION_ENTER}
                  >
                    <div className="search__flipboard-tv-link">
                      {t('search_flipboard_tv_link')}
                    </div>
                    <p className="ui-text--supporting">
                      {t('search_flipboard_tv_description')}
                    </p>
                  </WebLink>
                </div>
              )}
              {this.props.editorialBoards && (
                <RecommendedSectionList
                  sectionGroups={[
                    {
                      title: t('search_popular_topics'),
                      sections: this.props.editorialBoards,
                    },
                  ]}
                  onSelect={this.handleSelect}
                />
              )}
            </NavFromContext.Provider>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connector(
  connectModal,
  connectResponsive,
  connectAuthentication,
)(withT(withHistory(Search)));
