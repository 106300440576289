import { SEARCH_NAV_TYPES } from 'Webapp/action-types';

import { searchSections } from './search-actions';

/**
 * Search for sections based on a query string
 * @param  {String} query - Search term
 * @return {Function} - A function that takes the dispatch parameter
 */
export const search = (query) => async (dispatch) => {
  dispatch({ type: SEARCH_NAV_TYPES.SEARCH_NAV });
  const results = await dispatch(searchSections({ q: query }));

  dispatch({
    type: SEARCH_NAV_TYPES.SEARCH_NAV_SUCCEEDED,
    payload: results,
  });
};

export const clearSearch = () => ({ type: SEARCH_NAV_TYPES.CLEAR_SEARCH_NAV });
