import React from 'react';
import PropTypes from 'prop-types';
// components
import ModalWrapper from './modal-wrapper';
import SearchContainer from '../containers/search';

function SearchModal(props) {
  return (
    <ModalWrapper
      name="header-search"
      modalTheme={props.variant}
      modifier={props.modifier}
      position={props.position}
    >
      <SearchContainer autoFocus={props.autoFocus} />
    </ModalWrapper>
  );
}

SearchModal.propTypes = {
  variant: PropTypes.string,
  modifier: PropTypes.string,
  autoFocus: PropTypes.bool,
  position: PropTypes.object,
};

SearchModal.defaultProps = {
  variant: null,
  autoFocus: false,
  position: null,
};

export default SearchModal;
